<template>
  <div id="app">
    <div class="main">
      <div class="sidebar">
        <a
          href="/"
          aria-current="page"
          class="logo router-link-exact-active router-link-active"
        ></a
        ><a
          href="/"
          aria-current="page"
          class="site-name router-link-exact-active router-link-active"
          >绿描网页版</a
        >
        <div class="locale">
          <span class="lang">中文</span><span class="space">|</span
          ><span class="lang">English</span><span class="space">|</span
          ><span class="lang">日本語</span>
        </div>
        <div class="sidebar-menu">
          <router-link
            v-for="nav in navs"
            :key="nav.id"
            :to="nav.path"
            class="router-link-exact-active router-link-active"
            :class="{ 'is-active': $route.path === nav.path }"
          >
            {{ nav.name }}
          </router-link>
        </div>
        <div class="login">
          <div class="not-login"><span class="login-btn">登录账号</span></div>
        </div>
      </div>
      <div class="page sidebar-offset">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      navs: [
        {
          id: 0,
          name: "图片文字提取(基础版)",
          path: "/",
        },
        {
          id: 1,
          name: "图片文字提取(高级版)",
          path: "/imgVip",
        },
        // {
        //   id: 1,
        //   name: "trc20付款",
        //   path: "/trx",
        // },
      ],
    };
  },
};
</script>
<style lang="scss"></style>
