import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CryptoJS from "crypto-js";
dayjs.extend(utc);
dayjs.extend(timezone);

import axios from "axios";
import to from "await-to-js";
const baseURL = process.env.VUE_APP_API;
const instance = axios.create({
  baseURL: `${baseURL}`,
  timeout: 60 * 1000 * 2,
  responseType: "json", // Add this line to set the default responseType
});
instance.interceptors.request.use(
  (config) => {
    if (config.data && typeof config.data === "object") {
      // 创建一个 FormData 实例
      const formData = new FormData();

      // 遍历请求参数对象，将每个参数添加到 FormData 中
      for (let key in config.data) {
        formData.append(key, config.data[key]);
      }

      // 将 FormData 设置为请求的参数
      config.data = formData;
    }
    config.params = Object.assign({}, config.params || {}, {
      // reqConfig: process.env.VUE_APP_FIX,
    });
    const time = dayjs().tz("Asia/Shanghai").format("YYYY-MM-DD HH");
    const preStr = `${window.location.hostname}${time}${navigator.userAgent}`;
    config.headers["X-User-Agent"] = CryptoJS.HmacSHA256(
      preStr,
      "process.env.VUE_APP"
    ).toString(CryptoJS.enc.Hex);
    return config;
  },
  (err) => Promise.reject(err)
);
// eslint-disable-next-line no-unused-vars
const specialCode = [];
instance.interceptors.response.use(
  (res) => {
    const result = res.data || {};
    if (result.code !== 200) {
      return Promise.reject(result);
    }
    return result;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

// 2. 导出一个通过新axios实例调用接口的函数
export default ({
  url,
  method = "get",
  params,
  data,
  headers,
  responseType,
  noAutoFormData = true,
}) => {
  const promise = instance({
    url,
    method,
    params,
    data,
    responseType,
    headers,
    noAutoFormData,
  });
  return to(promise);
};
