import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/imgVip",
    name: "ImgVip",
    component: HomeView,
  },
  {
    path: "/trx",
    name: "trx",
    component: () => import(/* webpackChunkName: "about" */ "../views/trx.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
