<template>
  <div class="page-ocr">
    <div class="title">
      -
      {{ vipPage ? "图片文字提取（高级版）" : "图片文字提取（基础版）" }}（OCR）
    </div>
    <div class="board">
      <div class="board-inside" @paste="handlePaste">
        <div class="board-content">
          <div class="btn-upload" v-if="!imgs.length">
            从剪切板粘贴截图(Ctrl+V )
          </div>
          <div class="content-images flex-wrap d-flex">
            <ul
              class="content-images-ul"
              v-for="(items, idx) in imgs"
              :key="idx"
              v-loading="items.loading"
            >
              <li
                hash="861e18d47cdbf8708e3d61bbb6e5505093ecbec1"
                draggable="false"
                class=""
              >
                <div class="image-wrap">
                  <div
                    class="image"
                    :style="{
                      backgroundImage: `url(${items.url})`,
                    }"
                  ></div>
                  <div class="image-footer"></div>
                  <div v-if="items.text" @click="open(items)">
                    <div class="box-mask"></div>
                    <div class="loader-wrap loader-small preview-btn">
                      <div class="loader-tips">识别完成</div>
                      <div class="loader-tips">点击查看结果</div>
                    </div>
                  </div>

                  <div class="image-ops-delete">
                    <span class="close-bg-mask"></span
                    ><span class="close-bg" @click="remove(idx)"></span>
                  </div>
                  <div
                    class="image-ops"
                    v-if="!items.text"
                    @click="ajaxUpload(items)"
                  >
                    <span class="image-ops-recognize">识别</span>
                  </div>
                </div>
                <div title="126962@2x.png" class="filename-wrap">
                  {{ items.file.name }}
                </div>
              </li>
            </ul>
          </div>
          <div v-if="item.text">
            <div class="screen-mask" @click="closeView"></div>
            <div class="view-result fast">
              <div class="close" @click="closeView">
                <span class="close-bg"></span>
              </div>
              <div class="view-columns">
                <div class="view-column">
                  <div>原图</div>
                  <div class="column-image">
                    <ul>
                      <li>
                        <img :src="item.url" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="view-column">
                  <div class="column-split-title">|</div>
                  <div class="column-split"></div>
                </div>
                <div class="view-column">
                  <div>识别结果</div>
                  <div class="column-text">
                    <div class="quillWrapper">
                      <div class="ql-toolbar ql-snow">
                        <span class="ql-formats"></span>
                      </div>
                      <div id="quill-container" class="ql-container ql-snow">
                        <div
                          class="ql-editor p-t-12"
                          data-gramm="false"
                          contenteditable="true"
                        >
                          <VueEditor v-html="item.text" />
                        </div>
                      </div>
                      <!---->
                    </div>
                    <div class="bottom-btns" @click="copy">
                      <span class="btn">复制</span>
                      <!-- <span class="space">|</span>
                      <span class="btn">导出DOCX</span>
                      <span class="space">|</span>
                      <span class="btn">导出TXT</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/request";
import { VueEditor } from "vue2-quill-editor";
export default {
  data() {
    return {
      imgs: [],
      item: {},
    };
  },
  components: {
    VueEditor,
  },
  computed: {
    vipPage() {
      return this.$route.name === "ImgVip";
    },
  },
  methods: {
    fileName() {
      return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    },
    remove(index) {
      this.imgs.splice(index, 1);
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsDataURL(file);
      });
    },
    async handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (clipboardData) {
        // 获取粘贴内容中的文件项
        const items = clipboardData.items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            // 获取图片文件
            const file = items[i].getAsFile();
            if (file) {
              const base64 = await this.fileToBase64(file);

              // 生成图片预览的 URL
              this.imgs.push({
                url: URL.createObjectURL(file),
                file: file,
                fileName: this.fileName(),
                loading: false,
                text: "",
                base64,
              });
            }
          }
        }
      }
    },
    async ajaxUpload(v) {
      if (v.loading) return;
      const data = {};
      data.file = v.file;
      data.fileName = v.fileName;
      data.base64 = v.base64;
      v.loading = true;
      const [err, res] = await request({
        url: this.vipPage ? "/imagVipTxt" : "/imagTxt",
        method: "post",
        data: data,
      });
      v.loading = false;
      if (err) {
        this.$message.error(err.msg || "识别失败");
      }
      this.imgs.forEach((item) => {
        if (item.fileName === res.fileName) {
          if (Array.isArray(res.text)) {
            res.text = res.text.map((v) => v.words).join("\n");
          }
          item.text = this.convertToPTags(res.text);
        }
      });
    },
    convertToPTags(v) {
      // 将数据按换行符分割，并为每行包裹 <p> 标签
      const paragraphs = v.split("\n").map((line) => {
        return `<p>${line.trim()}</p>`;
      });

      // 将每段内容连接成 HTML 格式
      return paragraphs.join("");
    },
    open(v) {
      this.item = v;
      console.log(v);
    },
    closeView() {
      this.item = {};
    },
    copy() {
      const editor = document.querySelector(".ql-editor");
      if (!editor) {
        this.$message.error("未找到编辑器内容");
        return;
      }

      // 去掉两端的空行和空格
      const text = editor.innerText
        .replace(/^\s*[\r\n]/gm, "") // 去掉空行
        .trim(); // 去掉两端空格

      if (!text) {
        this.$message.warning("内容为空，无法复制");
        return;
      }

      // 创建一个临时的文本框以实现复制功能
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);

      // 选择并复制内容
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      this.$message.success("复制成功");
    },
  },
};
</script>
